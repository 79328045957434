/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, gsap, Swiper) => {

        const speed = 800

        let items = document.querySelectorAll('.slider_text')

        items.forEach(function(node) {
            let sliderImages = node.querySelector('.slider_text-slider'),
                parameters = {
                    simulateTouch: false,
                    slidesPerView: 1,
                    loop: false,
                    speed: speed
                }

            const swiperImages = new Swiper(sliderImages, parameters)

            swiperImages.on('slideChange', function() {
                sliderImages.querySelectorAll('.slider_text-images-item').forEach(function(image) {
                    app.lazyLoader.observe(image)
                })

                let index = this.realIndex

                let currentPagination = node.querySelectorAll('.slider_text-pagination .slider_text-pagination-item')[index]

                gsap.to(node.querySelector('.slider_text-pagination-cursor'), {
                    top: currentPagination.offsetTop,
                    duration: speed / 1000,
                    ease: 'back.inOut'
                })
            })

            node.querySelectorAll('.slider_text-pagination-item').forEach(function(el, index) {
                el.addEventListener('click', function() {
                    swiperImages.slideTo(index)
                })
            })

        })
    }
}
