/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, customGoogleMap) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $map = $('#map-showrooms')
        ;


        // map.center_map(map.gmap)

        /*
		|
		| initGoogleMap
		|----------------
		|
		*/
        let map = new customGoogleMap($map, {
                'markers': $map.find('.marker'),
                'zoom': 12
            }),
            $search = $('#showroom-search'),
            $searchTrigger = $('.search-icon'),
            autocomplete = new google.maps.places.Autocomplete($search[0]),
            geocoder = new google.maps.Geocoder()

        autocomplete.bindTo('bounds', map.gmap)

        autocomplete.addListener("place_changed", () => {
            codeAddress()
        })

        $searchTrigger.on('click', function() {
            codeAddress()
        })

        $('body').on('keydown', $search, function(e) {
            if (e.keyCode === 13) {
                codeAddress()
            }
        })

        function codeAddress() {
            let address = $search.val()

            geocoder.geocode( {'address': address}, function(results, status) {
                if (status == 'OK') {
                    let position = {
                        latitude: results[0].geometry.location.lat(),
                        longitude: results[0].geometry.location.lng()
                    };

                    let minDistance = 1000000;
                    let closestMarker = null;

                    $.each(map.markers, function(key, marker) {
                        let distance = getDistance(position, marker.position);

                        if (distance < minDistance) {
                            minDistance = distance;
                            closestMarker = marker;
                        }

                        $('.card-store[data-showroom_id="'+ marker.showroom_id +'"]').css({
                            order: Math.trunc(distance)
                        })
                    });

                    map.gmap.setCenter(closestMarker.position);

                    map.gmap.setZoom(13);

                    showVisibleMarkers()
                }
            });
        }

        function showVisibleMarkers() {
            let bounds = map.gmap.getBounds()

            $.each(map.markers, function(key, marker) {
                let isVisible = bounds.contains(marker.getPosition()) === true && marker.visible

                $('.card-store[data-showroom_id="'+ marker.showroom_id +'"]').toggleClass('--not-visible', !isVisible)
            });
        }

        map.gmap.addListener('bounds_changed', function() {
            showVisibleMarkers()
        });

        // get km distance
        var rad = function (x) {
            return x * Math.PI / 180;
        };

        //p1 c'est notre position
        var getDistance = function (p1, p2) {
            var R = 6378137; // Earth’s mean radius in meter
            var dLat = rad(p2.lat() - p1.latitude);
            var dLong = rad(p2.lng() - p1.longitude);
            var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.latitude)) * Math.cos(rad(p2.lat())) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = R * c;
            return d; // returns the distance in meter
        };

    }
}
