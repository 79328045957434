/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, customGoogleMap, Swiper, Navigation, Pagination, Ajax) => {

        /*
        |
        | Constants
        |------------
        */
        const
            $map = $('#map-showrooms'),
            // $contactForm = $('.contact-form')
            $slidersTeam 		= $('[data-swiper="team-contact"]')
        ;

		/**
		 * Slider Team (w/ Swiper)
		 */
        let swiperMember = null;
        let swiperTeamOptions = {

            // configure Swiper to use modules
            modules: [Navigation, Pagination],

            // Optional parameters
            // loop: true,
            allowTouchMove: false,
            slidesPerView: 1,
            spaceBetween: 32,

            // If we need pagination
            pagination: {
                el: '',
                clickable: true
            },

            // Navigation arrows
            navigation: {
                nextEl: '',
                prevEl: '',
            },

            breakpoints: {
                768: {
                    slidesPerView: 2,
                },
                1024: {
                    slidesPerView: 3,
                },
                1280: {
                    slidesPerView: 4,
                },
            }

        };

        /*
		|
		| Form logic
		|----------------
		|
		*/
        const

            $doubleCTA     = $('.double_cta'),
            $doubleCTALink = $doubleCTA.find('.link-form'),

            $textImageCta  = $('.text_image_cta'),

            $sectionForm   = $('.section-form'),
            $conceptStores = $('.section-concept-stores'),

            $backBtn       = $('.back-btn'),
            $breadcrumbs   = $('.breadcrumb-item'),
            $steps         = $('.step'),
            $ctaShowroom   = $('.cta-showroom'),
            $ctaRdv        = $('.cta-rdv'),
            $ctaBenefit    = $('.cta-benefit'),
            $swiperSlide   = $('.swiper-slide')
        ;

        let
            direction = 'forward',
            previousStepIndex = 0,
            stepIndex = 0,

            universe = undefined,
            showroomID = undefined,
            typeRdv = undefined,
            memberID = undefined,
            benefitID = undefined,

            pushRdvItemID = undefined,
            pushRdvStoreID = undefined,
            pushRdvMemberID = undefined,
            pushRdvBenefitID = undefined,
            pushRdvObject = {
                'store'   : undefined,
                'benefit' : undefined,
                'member'  : undefined,
            },
            pushRdvReady = false,
            pushRdvQuick = false,
            pushRdvBaseUrl = document.querySelector('.section-form').dataset.pushrdvUrl,
            pushRdvBaseUrlBis = document.querySelector('.section-form').dataset.pushrdvUrlBis,
            pushRdvUrl = null
        ;

        const forward = () => {

            direction = 'forward';

            if ( stepIndex < 4 ) {

                if (!pushRdvReady) {
                    
                    previousStepIndex = stepIndex;
                    stepIndex++;

                }

                manageSteps();

            }
            
        }

        const backward = () => {

            pushRdvItemID = undefined;
            direction = 'backward';

            if ( stepIndex > 0 ) {

                previousStepIndex = stepIndex;

                if (stepIndex == 4 && typeRdv == 'first') {
                    stepIndex-=2;
                } else {
                    stepIndex--;
                }

                manageSteps();

            }

        }

        const manageLayout = () => {

            if ( direction == 'forward' ) {

                if (previousStepIndex == (stepIndex - 1)) {

                    $(`.breadcrumb-item[data-step-key="${ stepIndex - 1 }"]`).addClass('blur');
                    $(`.breadcrumb-item[data-step-key="${ stepIndex }"]`).addClass('active');
                    $(`.step[data-step-key="${ stepIndex - 1 }"]`).removeClass('active');
                    $(`.step[data-step-key="${ stepIndex }"]`).addClass('active');
                    
                } else {
                    
                    $(`.breadcrumb-item[data-step-key="${ stepIndex - 2 }"]`).addClass('blur');
                    $(`.breadcrumb-item[data-step-key="${ stepIndex }"]`).addClass('active');
                    $(`.step[data-step-key="${ stepIndex - 2 }"]`).removeClass('active');
                    $(`.step[data-step-key="${ stepIndex }"]`).addClass('active');

                }

            }

            if ( direction == 'backward' ) {

                if (previousStepIndex == (stepIndex + 1)) {

                    $(`.breadcrumb-item[data-step-key="${ stepIndex + 1 }"]`).removeClass('active');
                    $(`.breadcrumb-item[data-step-key="${ stepIndex }"]`).removeClass('blur');
                    $(`.step[data-step-key="${ stepIndex + 1 }"]`).removeClass('active');
                    $(`.step[data-step-key="${ stepIndex }"]`).addClass('active');
                    
                } else {
                    
                    $(`.breadcrumb-item[data-step-key="${ stepIndex + 2 }"]`).removeClass('active');
                    $(`.breadcrumb-item[data-step-key="${ stepIndex }"]`).removeClass('blur');
                    $(`.step[data-step-key="${ stepIndex + 2 }"]`).removeClass('active');
                    $(`.step[data-step-key="${ stepIndex }"]`).addClass('active');

                }

            }

        }

        const manageSteps = () => {

            // console.log('----------');
            // console.log('Direction : ', direction);
            // console.log('StepIndex : ', stepIndex);
            // console.log('----------');

            manageLayout();

            if ( direction == 'forward' ) {

                if ( stepIndex == 1 ) {

                    // Get all select's and disable each options which have nothing to do with selected universe.
                    document.querySelectorAll(`.select-benefit option`).forEach(element => {

                        let optionUniverse = element.dataset.benefitsUniverse;
        
                        if ( !(optionUniverse == undefined || optionUniverse == '') ) {
        
                            if ( optionUniverse.includes(universe) ) {
        
                                element.removeAttribute('disabled');
                                
                            } else {
                                
                                element.setAttribute('disabled', '');
        
                            }
                            
                        }
        
                    });

                }

                if ( stepIndex == 2 ) {

                    // Display right Members (Swiper Container) according to 'active' showroom ID.
                    const $relatedMembers = $(`.members .members-showroom[data-showroom-id="${ showroomID }"]`);
                    $relatedMembers.addClass('active');
                    
                    // Display right Benefit (Selects Container) according to 'active' showroom ID.
                    const $relatedSelects = $(`.selects-showroom[data-showroom-id="${ showroomID }"]`);
                    $relatedSelects.addClass('active');
                    
                    // Display right Benefit (Cards Container) according to 'active' showroom ID.
                    const $relatedBenefits = $(`.benefits-showroom[data-showroom-id="${ showroomID }"]`);
                    $relatedBenefits.addClass('active');

                }

                if ( stepIndex == 3 ) {

                    if (typeRdv == 'not-first') {

                        // Get & Initialize right Swiper who 'active' class is present for right showroom.
                        const
                            $sliderContainer = $(`.members-showroom.active`),
                            $sliderMembers = $sliderContainer.find('[data-swiper="team-contact"]')
                        ;
    
                        let
                            $paginationCustom = $sliderContainer.find('.swiper-pagination-custom'),
                            $navNextCustom 	  = $sliderContainer.find('.swiper-button-next-custom'),
                            $navPrevCustom    = $sliderContainer.find('.swiper-button-prev-custom')
                        ;
    
                        swiperTeamOptions.pagination.el     = $paginationCustom.get(0);
                        swiperTeamOptions.navigation.nextEl = $navNextCustom.get(0);
                        swiperTeamOptions.navigation.prevEl = $navPrevCustom.get(0);
    
                        swiperMember = new Swiper($sliderMembers.get(0), swiperTeamOptions);

                    }

                    // Display right Benefit (Select) according to typeRDV in 'active' showroom.
                    $(`.selects-showroom.active .select-benefit[data-benefits-type="${ typeRdv }"]`).addClass('active');
                    // Display right Benefit (Cards) according to typeRDV in 'active' showroom.
                    $(`.benefits-showroom.active .sub-benefits[data-benefits-type="${ typeRdv }"]`).addClass('active');

                    // Get & Add event to 'active' select for 'active' showroom.
                    const $selectBenefit = $('.select-benefit.active');

                    $selectBenefit.on('change', (e) => {
        
                        let value = e.currentTarget.value;
        
                        let $option = $selectBenefit.find(`option[value="${value}"]`);
        
                        let key = $option.get(0).dataset.benefitKey;
        
                        $(`.sub-benefits.active .card-benefit`).removeClass('active');
                        $(`.sub-benefits.active .card-benefit[data-benefit-id="${ key }"]`).addClass('active');
        
                    });

                }

                if ( stepIndex == 4 ) {

                    if (typeRdv == 'first') {

                        // Display right Benefit (Select) according to typeRDV in 'active' showroom.
                        $(`.selects-showroom.active .select-benefit[data-benefits-type="${ typeRdv }"]`).addClass('active');
                        // Display right Benefit (Cards) according to typeRDV in 'active' showroom.
                        $(`.benefits-showroom.active .sub-benefits[data-benefits-type="${ typeRdv }"]`).addClass('active');

                        // Get & Add event to 'active' select for 'active' showroom.
                        const $selectBenefit = $('.select-benefit.active');

                        $selectBenefit.on('change', (e) => {
            
                            let value = e.currentTarget.value;
            
                            let $option = $selectBenefit.find(`option[value="${value}"]`);
            
                            let key = $option.get(0).dataset.benefitKey;
            
                            $(`.sub-benefits.active .card-benefit`).removeClass('active');
                            $(`.sub-benefits.active .card-benefit[data-benefit-id="${ key }"]`).addClass('active');
            
                        });

                    } else {

                        document.querySelectorAll(`.select-benefit.active option[data-benefits-universe~="${universe}"]`).forEach(element => {
    
                            let optionMembers = element.dataset.benefitMembers;
            
                            if ( !(optionMembers == undefined || optionMembers == '') ) {
            
                                if ( optionMembers.includes(memberID) ) {
            
                                    element.removeAttribute('disabled');
                                    
                                } else {
                                    
                                    element.setAttribute('disabled', '');
            
                                }
                                
                            }
            
                        });

                    }
                    
                }

            }

            if ( direction == 'backward' ) {

                if ( stepIndex == 0 ) {

                    // Get all select's and remove disable attr for each options
                    document.querySelectorAll(`.select-benefit option`).forEach(element => {

                        let optionUniverse = element.dataset.benefitsUniverse;
        
                        if ( !(optionUniverse == undefined || optionUniverse == '') ) {
        
                            element.setAttribute('disabled', '');
                            
                        }
        
                    });

                    // Reset 'Universe' select to default (first option)
                    document.querySelector('.double_cta .custom-select .custom-select-item').value = '';

                }

                if ( stepIndex == 1 ) {

                    universe = null

                }

                if ( stepIndex == 2 ) {

                    // Remove 'active' class to all Select's & Card's and Their container's
                    $(`.select-benefit`).removeClass('active');
                    $(`.sub-benefits`).removeClass('active');
                    $(`.card-benefit`).removeClass('active');
                    $(`.select-benefit`).find('option:first').prop('selected', true);

                    // Destroy the only initialized Swiper    
                    if (swiperMember != null) {
                        swiperMember.destroy();
                    }

                }
    
                if ( stepIndex == 3 ) {

                    $(`.card-benefit`).removeClass('active');
                    $(`.select-benefit`).find('option:first').prop('selected', true);
    
                }

            }

            if ( stepIndex > 0 ) {

                $doubleCTA.addClass('hide');
                $textImageCta.addClass('hide');
                $sectionForm.addClass('active');

            } else {

                $sectionForm.removeClass('active');
                $doubleCTA.removeClass('hide');
                $textImageCta.removeClass('hide');

            }

            if ( stepIndex == 1 ) {

                map.center_map(map.gmap)

                $conceptStores.addClass('active');

                $(`.selects-showroom`).removeClass('active');
                $(`.benefits-showroom`).removeClass('active');
                $(`.members .members-showroom`).removeClass('active');

            } else {

                $conceptStores.removeClass('active');

            }

        }

        const pushrdvOpening = () => {

            const isNotNull = Object.entries(pushRdvObject).every(entry => {

                let key = entry[0];
                let value = entry[1];

                if (key != 'member') {
                    
                    if (value === null) {
                        return false;
                    }
                    
                }

                return true;
            });
debugger;
            if ( isNotNull == true ) {

                if ( pushRdvBaseUrl.length > 0 || pushRdvBaseUrlBis.length > 0  ) {

				let storeName;
			    if (pushRdvObject.store.indexOf('pushrdv.com/rdv/') !== -1){
			    	storeName = pushRdvObject.store.split('pushrdv.com/rdv/')[1];
			    } else {
				    storeName = pushRdvObject.store;
			    }

                    let slug;
                    if (typeRdv == 'first') {
                        slug = `guichet_${storeName}_${pushRdvObject.benefit}`;
                    } else {
                        if (pushRdvQuick === true || pushRdvObject.member === null) {
                            slug = `guichet_${storeName}_${pushRdvObject.benefit}`;
                        } else {
			slug = `${storeName}_${pushRdvObject.benefit}_${pushRdvObject.member}`;
                        } 
                    }
                    pushRdvUrl = new URL(slug, pushRdvBaseUrl);
                   console.log(pushRdvUrl.toString());
                    window.open(pushRdvUrl, '_blank');

                } else {

                    console.log('Error : Base URL Not OK');

                }

            } else {

                console.log('Error : Object Null');

            }

            pushRdvReady = false;
            pushRdvQuick = false;

        }

        const managePushrdvObject = (pushRdvItemID) => {

            if ( direction == 'forward' ) {

                if ( stepIndex == 1 ) {

                    pushRdvObject.store = pushRdvItemID;

                }

                if ( stepIndex == 3 ) {

                    if (pushRdvItemID != '') {
                        
                        pushRdvObject.member = pushRdvItemID;

                    }
                    
                }
                
                if ( stepIndex == 4 ) {
                    
                    pushRdvObject.benefit = pushRdvItemID;

                }

            }

            if ( direction == 'backward' ) {

                if ( stepIndex == 0 ) {

                    pushRdvObject.store = pushRdvItemID;

                }

                if ( stepIndex == 3 ) {

                    pushRdvObject.member = pushRdvItemID;
                    
                }
                
                if ( stepIndex == 4 ) {
                    
                    pushRdvObject.benefit = pushRdvItemID;

                }

            }

            if (pushRdvReady) {

                pushrdvOpening();

            }

        }

        $backBtn.on('click', (e) => {

            backward();
            managePushrdvObject(pushRdvItemID );

        });

        $doubleCTALink.on('click', (e) => {

            e.preventDefault();

            let selectUniverse = document.querySelector('.double_cta .custom-select .custom-select-item');

            if (selectUniverse.value != '') {
                
                universe = selectUniverse.value;
                
                forward();

            }

        });

        $ctaShowroom.on('click', (e) => {

            showroomID = e.currentTarget.dataset.showroomId;
            pushRdvStoreID = e.currentTarget.dataset.showroomPushrdvId;
            pushRdvItemID = pushRdvStoreID != '' ? pushRdvStoreID : null;

            managePushrdvObject(pushRdvItemID);
            forward();

        });

        $ctaRdv.on('click', (e) => {

            typeRdv = e.currentTarget.dataset.ctaType

            if (typeRdv != 'first') {
                
                forward();
                
            } else {
                
                direction = 'forward';
                previousStepIndex = stepIndex;
                stepIndex+=2;

                manageSteps();

            }

        });

        $swiperSlide.on('click', (e) => {

            memberID = e.currentTarget.dataset.memberId;
            pushRdvMemberID = e.currentTarget.dataset.memberPushrdvId;
            pushRdvItemID = pushRdvMemberID != '' ? pushRdvMemberID : null;
            pushRdvQuick = pushRdvMemberID == '' ? true : false;

            managePushrdvObject(pushRdvItemID);
            forward();

        });

        $ctaBenefit.on('click', (e) => {

            benefitID = e.currentTarget.dataset.benefitId
            pushRdvBenefitID = e.currentTarget.dataset.benefitPushrdvId
            pushRdvItemID = pushRdvBenefitID != '' ? pushRdvBenefitID : null;
            pushRdvReady = true;

            managePushrdvObject(pushRdvItemID);

        });

        /*
		|
		| Store Search (w/ Ajax)
		|----------------
		|
		*/
        // const $showroomSearch = $('#showroom-search');

        // if ( $showroomSearch.length ) {

        //     let
        //         typingTimer,
        //         doneTypingInterval = 2000
        //     ;

        //     let
        //         postsContainer = document.querySelector('.find-showroom .left .list'),
        //         xhr = null,
        //         url = null,
        //         keyword = null
        //     ;

        //     const doneTyping = () => {

        //         keyword = $showroomSearch.val();

        //         if ( keyword.length > 0 ) {

        //             url = `ajax/stores/${keyword}/`;
        //             console.log( url );

        //             // if(xhr) xhr.abort()

        //             // xhr = new Ajax({
        //             //     url: url,
        //             //     // data: {
        //             //     //     keyword: keyword
        //             //     // },
        //             //     onLoadStart: () => {
        //             //         postsContainer.classList.add('loading')
        //             //     },
        //             //     onSuccess: (response) => {
        //             //         postsContainer.innerHTML += response

        //             //         postsContainer.classList.remove('loading')

        //             //         // if(postsContainer.querySelector('.no-more-post')) {
        //             //         //     loadMoreBtn.remove()
        //             //         // }
        //             //     }
        //             // })

        //         }

        //     }

        //     $showroomSearch.on('keyup', (e) => {

        //         clearTimeout(typingTimer);
        //         typingTimer = setTimeout(doneTyping, doneTypingInterval);

        //     });

        //     $showroomSearch.on('keydown', (e) => {

        //         clearTimeout(typingTimer);

        //     });

        // }


        /*
		|
		| initGoogleMap
		|----------------
		|
		*/
        let map = new customGoogleMap($map, {
                'markers': $map.find('.marker'),
                'zoom': 12
            }),
            $search = $('#showroom-search'),
            $searchTrigger = $('.search-icon'),
            autocomplete = new google.maps.places.Autocomplete($search[0]),
            geocoder = new google.maps.Geocoder()

        autocomplete.bindTo('bounds', map.gmap)

        autocomplete.addListener("place_changed", () => {
            codeAddress()
        })

        $searchTrigger.on('click', function() {
            codeAddress()
        })

        $('body').on('keydown', $search, function(e) {
            if (e.keyCode === 13) {
                codeAddress()
            }
        })

        function codeAddress() {
            let address = $search.val()

            geocoder.geocode( {'address': address}, function(results, status) {
                if (status == 'OK') {
                    let position = {
                        latitude: results[0].geometry.location.lat(),
                        longitude: results[0].geometry.location.lng()
                    };

                    let minDistance = 1000000;
                    let closestMarker = null;

                    $.each(map.markers, function(key, marker) {
                        let distance = getDistance(position, marker.position);

                        if (distance < minDistance) {
                            minDistance = distance;
                            closestMarker = marker;
                        }

                        $('.card-store[data-showroom_id="'+ marker.showroom_id +'"]').css({
                            order: Math.trunc(distance)
                        })
                    });

                    map.gmap.setCenter(closestMarker.position);

                    map.gmap.setZoom(16);

                    showVisibleMarkers()
                }
            });
        }

        function showVisibleMarkers() {
            let bounds = map.gmap.getBounds()

            $.each(map.markers, function(key, marker) {
                let isVisible = bounds.contains(marker.getPosition()) === true && marker.visible

                $('.card-store[data-showroom_id="'+ marker.showroom_id +'"]').toggleClass('--not-visible', !isVisible)
            });
        }

        map.gmap.addListener('bounds_changed', function() {
            showVisibleMarkers()
        });

        // get km distance
        var rad = function (x) {
            return x * Math.PI / 180;
        };

        //p1 c'est notre position
        var getDistance = function (p1, p2) {
            var R = 6378137; // Earth’s mean radius in meter
            var dLat = rad(p2.lat() - p1.latitude);
            var dLong = rad(p2.lng() - p1.longitude);
            var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.latitude)) * Math.cos(rad(p2.lat())) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
            var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
            var d = R * c;
            return d; // returns the distance in meter
        };



    }
}

