/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, Swiper, Navigation) => {

        let items = document.querySelectorAll('.swm-item')

        items.forEach(function(item) {
            let imageSlider = item.querySelector('.swm-item-image-slider'),
                contentSlider = item.querySelector('.swm-item-content-slider')

            let parameters = {
                simulateTouch: false,
                loop: true,
                modules: [Navigation],
                navigation: {
                    nextEl: item.querySelector('.swiper-button-next'),
                    prevEl: item.querySelector('.swiper-button-prev'),
                }
            }

            const imageSwiper = new Swiper(imageSlider, parameters)

            imageSwiper.on('slideChange', function() {
                imageSlider.querySelectorAll('.swiper-slide').forEach(function(item) {
                    app.lazyLoader.observe(item)
                })
            })

            const contentSwiper = new Swiper(contentSlider, parameters)
        })
    }
}
