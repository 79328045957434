/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, gsap, ScrollTrigger, SplitText, Menu, Kira) => {
		/*
		|
		| Constants
		|-----------
		*/
        const
            html              = document.querySelector('html'),
            body              = document.querySelector('body'),
            $body             = $('body'),
            $pageLoader       = $('.page-loader'),
            scrollAreas       = document.querySelectorAll('.scroll-area'),
            $splitItems       = $body.find('[data-splittext]'),
            $menuWrapper      = $('#mobile-menu'),
            $menuButton       = $('#header .btn-menu'),
            $menuBars         = $menuButton.find('.item-burger > span'),
            $menuClose        = $menuWrapper.find('.item-close'),
            $menuStaggerItems = $menuWrapper.find('[data-stagger-item]')
        ;


        /*
		|
		| SplitText
		|------------
        */
        $.each($splitItems, function() {
            const $item = $(this);
            const type = $item.data('splittext') ? $item.data('splittext') : 'words, chars';
            const split = new SplitText($item, { type: type, linesClass: 'item-line', wordsClass: 'item-word', charsClass: 'item-char' });

            $item[0]['split'] = split;
        });

        $body.on('dom:ready', () => {
            $splitItems.addClass('split-ready')
        } )


		/*
        |
        | Scroll Areas
        |---------------
        */
		Array.prototype.forEach.call(scrollAreas, element => {
			Scrollbar.init(element);
		});


        /*
		|
		| Loader
		|---------
        */
        if (sessionStorage.getItem('loaded_once') === null) {
            sessionStorage.setItem('loaded_once', 'loaded_once');
        }

        if ($pageLoader.hasClass('active')){
            const loaderTl = gsap.timeline({ paused: true, /*onComplete: () => $pageLoader.remove()*/ });

            loaderTl.to($pageLoader.find('.item-loadbar-inner'), 0.4, { scaleX: 1, ease: 'Power0.easeNone' }, 'start')
            loaderTl.to($pageLoader.find('.item-content'), 0.8, { autoAlpha: 0, ease: 'Power1.easeOut' }, '-=0')
            loaderTl.call(() => { app.dispachEvent(body, 'dom:ready'); }, null)
            loaderTl.to($pageLoader, 0.8, { autoAlpha: 0, ease: 'Power1.easeOut' }, '-=0')

            $(window).on('load', function () {
                loaderTl.play();

            });
        } else {
            $(window).on('load', function(){
                app.dispachEvent(body, 'dom:ready');
            })

        }


        /*
		|
		| Menu
		|-------
        */
        const
            menuWrapper         = document.querySelector('#mobile-menu'),
            menuButton          = document.querySelector('#header .burger')
        ;

        const menu = new Menu(menuWrapper, menuButton, {
            reverseTimeScale: 2
        });

        menu.menuTimeline
			.to($menuWrapper, {
                x: 0,
                duration: .6,
                ease: 'power1.easeOut'
            })

        menu.init();

        document.querySelector('body').addEventListener('click', (e) => {
            e.stopPropagation();
            if (e.currentTarget.classList.contains('menu-active')) {
                menu.close();
                gsap.to('.burger_drop', {
                    left: '100%',
                    duration: .6,
                    ease: 'power1.easeOut'
                })
            }
        });

        document.querySelector('#mobile-menu').addEventListener('click', (e) => {
            e.stopPropagation();
        });

        document.querySelector('.burger').addEventListener('click', (e) => {
            e.stopPropagation();
        });

        document.querySelector('.mobile_menu_close').addEventListener('click', function() {
            menu.close()
        })

        document.querySelectorAll('.burger_main_nav-link[data-index]').forEach(function(el) {
            let index = el.dataset.index

            el.addEventListener('click', function(event) {
                event.preventDefault()

                document.querySelectorAll('.burger_drop-item').forEach(function(el) {
                    el.style.display = 'none'
                })
                document.querySelector('.burger_drop-item[data-index="'+ index +'"]').style.display = 'block'

                gsap.to('.burger_drop', {
                    left: 0,
                    duration: .6,
                    ease: 'power1.easeOut'
                })
            })
        })

        document.querySelector('.burger_drop-back').addEventListener('click', function() {
            gsap.to('.burger_drop', {
                left: '100%',
                duration: .6,
                ease: 'power1.easeOut'
            })
        })

        /*
        |
		| Kira
		|-----------
        */
        const kira = new Kira({
            loadEvent: [body, 'dom:ready'],
            scrollTrigger: {
                start: 'top 75%',
                end: '+=400',
                scrub: true,
                markers: false,
                //scroller: scrollContainerSelector,
            },
            tweenParams: {
                start: '-=0.6'
            }
        });

        /*
        | fadeInUp
        |-----------
        */
        kira.add('fadeInUp', (item, timeline, start) => {
            timeline.fromTo(item, 1.3, { y: 50 }, { y: 0, autoAlpha: 1, ease: 'easeSmooth' }, start)
        });

        /*
        | splitline
        |------------
        */
        kira.add('splitline', (item, timeline, start) => {
            const delay = item.dataset.delay ? item.dataset.delay : 0.012;

            item.querySelectorAll('.item-line').forEach( line => {
                timeline.from($(line).find('> div, > em, > span'), 1.35, { y: '101%', ease: 'easeSmooth' }, start)
            } )
        });

        /*
        | translate-from
        |-----------
        */
        kira.add('translate-from', (item, timeline, start) => {
            timeline.from(item, { y: '+=120', duration: 1, ease: 'power1.out' }, start)
        });

        /*
        | translate-to
        |-----------
        */
        kira.add('translate-to', (item, timeline, start) => {
            timeline.to(item, { y: '-=120', duration: 1, ease: 'power1.out' }, start)
        })

        /*
        | title overflow
        |-----------
        */
        kira.add('title-overflow', (item, timeline, start) => {
            timeline.fromTo(item.querySelectorAll('.title-line'), {
                // clipPath: 'polygon(0% 0%, 100% 0%, 100% 0%, 0% 0%)'
                clipPath: 'polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%)'
            }, {
                clipPath: 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)',
                duration: .8,
                ease: 'power1.out',
                stagger: .2
            }, start)
        })

        kira.init();

	}
}
