/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, ) => {

        /*
        |
        | Constants
        |------------
        */
        const formContainer = document.querySelector('.form-container')


        /*
		|
		| Form Label
		|----------------
		|
		*/
        if (formContainer) {

            const form = formContainer.querySelector('form')
            const fieldsCtr = form.querySelectorAll('.gfield[id]')
            const fieldsToFocus = []

            fieldsCtr.forEach(element => {

                const field = element.querySelector(':scope .ginput_container > *')
                
                if (field.tagName == "INPUT" || field.tagName == "TEXTAREA") {

                    if (field.tagName == "TEXTAREA") {

                        fieldsToFocus.push(field.closest('.gfield'));
                        
                    } else {

                        if (field.type != 'checkbox') {

                            fieldsToFocus.push(field.closest('.gfield'));
                            
                        }

                    }
                    
                }
                
            });

            fieldsToFocus.forEach(element => {

                const field = element.querySelector(':scope .ginput_container > *')

                field.addEventListener('focus', e => {

                    element.classList.add('focused')

                });

                field.addEventListener('blur', e => {

                    element.classList.remove('focused')

                });
                
            });
            
        }



    }
}
