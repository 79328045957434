/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, gsap, Swiper, Navigation) => {

        const speed = 800

        let items = document.querySelectorAll('.slider_product')

        items.forEach(function(item) {
            let slider = item.querySelector('.slider_product-slider')

            const swiper = new Swiper(slider, {
                simulateTouch: false,
                slidesPerView: 2,
                spaceBetween: 32,
                loop: true,
                modules: [Navigation],
                navigation: {
                    nextEl: item.querySelector('.swiper-button-next'),
                    prevEl: item.querySelector('.swiper-button-prev'),
                },
                speed: speed,
                breakpoints: {
                    768: {
                        slidesPerView: 3
                    },
                    1024: {
                        slidesPerView: 4
                    }
                }
            })

            swiper.on('slideChange', function() {
                slider.querySelectorAll('.product_card-image').forEach(function(item) {
                    app.lazyLoader.observe(item)
                })

                let index = this.realIndex

                let currentPagination = item.querySelectorAll('.slider_product-pagination .slider_product-pagination-item')[index]

                gsap.to(item.querySelector('.slider_product-pagination-cursor'), {
                    left: currentPagination.offsetLeft,
                    duration: speed / 1000,
                    ease: 'back.inOut'
                })
            })

        })
    }
}
