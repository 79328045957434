/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, gsap, Swiper, Navigation, Pagination) => {
		/*
		|
		| Constants
		|-----------
		*/
        const
			$body 				= $('body')
		;

		/**
         * Modal - Matterport (StreetView)
         */
		const
			$modalVisit    = $('#modal-matterport'),
			$ctaVisitPopin = $('#visit-popin'),
			closeBtnModal  = $('.close-btn-modal')
		;

		if ( $ctaVisitPopin.length ) {
			
			$modalVisit.iziModal({
				width  : '85%',
				height : '85%',
				onOpened: function(e){

					const $closeBtnModal = e.$element.find('.close-btn-modal');

					$closeBtnModal.on('click', (e) => {

						$modalVisit.iziModal('close');

					});

				},
			});

			$ctaVisitPopin.on('click', (e) => {

				e.preventDefault();

				let
					$currentTarget = $(e.currentTarget),
					matterportSrc = $currentTarget.get(0).dataset.visit
				;

				$modalVisit.find('iframe').attr('src', `${ matterportSrc }`);

				$modalVisit.iziModal('open');
				
			});
			
		}

		/*
		|
		| DOM Events
		|-----------------------
		*/

	}
}
