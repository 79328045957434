/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, gsap, Plyr, Swiper, Navigation, Pagination) => {
		/*
		|
		| Constants
		|-----------
		*/
        const
			$body 				= $('body'),
			$header 			= $('[data-header]'),
			$sliderTeam 		= $('[data-swiper="team"]'),
			$sliderNews 		= $('[data-swiper="news"]'),
			$sliderTestimonials = $('[data-swiper="testimonials"]'),
			$flexibleVideos 	= $('.flexible-video'),
			$sectionProducts	= $('.section-products')
		;


		/**
         * Sticky Header (Scroll)
         */
		let
			offset 	   	  = 0,
			scrollTop  	  = 0,
			lastScrollTop = 0
		;

		const stickyHeader = (scrollTop, offset, lastScrollTop) => {

			if ( scrollTop > offset ) {

				$header.get(0).dataset.header = 'change';

				if ( scrollTop > lastScrollTop ) {

					$header.get(0).dataset.header = 'change hide';

				} else {

					$header.get(0).dataset.header = 'change';

				}

			} else {

				$header.get(0).dataset.header = '';

			}

		}

		if ( $header.length ) {

			offset 	   = $header.outerHeight();
			scrollTop  = $( window ).scrollTop();

			stickyHeader(scrollTop, offset, lastScrollTop);

		}

		/**
		 * Slider Team (w/ Swiper)
		 */
		let swiperTeam = null;

		if ( $sliderTeam.length ) {

			$sliderTeam.each(function() {
				let slider = this,
					$flexibleTeam = $(this).parents('.flexible-team'),
					flexibleTeam = $flexibleTeam[0],
					$paginationCustom = $flexibleTeam.find('.swiper-pagination-custom'),
					$navNextCustom 	  = $flexibleTeam.find('.swiper-button-next-custom'),
					$navPrevCustom    = $flexibleTeam.find('.swiper-button-prev-custom')

				let swiperTeamOptions = {

					// configure Swiper to use modules
					modules: [Navigation, Pagination],

					// Optional parameters
					loop: true,
					spaceBetween: 32,

					// If we need pagination
					// pagination: {
					// 	el: $paginationCustom.get(0),
					// 	clickable: true
					// },

					// Navigation arrows
					navigation: {
						nextEl: $navNextCustom.get(0),
						prevEl: $navPrevCustom.get(0),
					},

				};

				if ( !($sliderTeam.data('swiperBig')) ) {
					swiperTeamOptions = {
						...swiperTeamOptions,
						'slidesPerView:': 1,
						'breakpoints': {
							'768': {
								'slidesPerView': 2,
							},
							'1024': {
								'slidesPerView': 3,
							},
							'1280': {
								'slidesPerView': 4,
							},
						}
					};
				}

				swiperTeam = new Swiper(slider, swiperTeamOptions);

				swiperTeam.on('slideChange', function() {
					let index = this.realIndex

					let currentPagination = flexibleTeam.querySelectorAll('.swiper-pagination-custom .swiper-pagination-custom-item')[index]

					gsap.to(flexibleTeam.querySelector('.swiper-pagination-custom-cursor'), {
						left: currentPagination.offsetLeft,
						duration: .8,
						ease: 'back.inOut'
					})
				})

				flexibleTeam.querySelectorAll('.swiper-pagination-custom-item').forEach(function(el, index) {
					el.addEventListener('click', function() {
						swiperTeam.slideToLoop(index)
					})
				})
			})

		}

		/**
		 * Slider News (w/ Swiper)
		 */
		let swiperNews = null;

		if ( $sliderNews.length ) {

			$sliderNews.each(function() {
				let slider = this,
					$flexibleNews = $(this).parents('.flexible-news'),
					flexibleNews = $flexibleNews[0],
					$navNextCustom = $flexibleNews.find('.swiper-button-next-custom'),
					$navPrevCustom = $flexibleNews.find('.swiper-button-prev-custom')

				swiperNews = new Swiper(slider, {

					// configure Swiper to use modules
					modules: [Navigation, Pagination],

					// Optional parameters
					loop: true,
					slidesPerView: 1,
					spaceBetween: 32,

					// If we need pagination
					// pagination: {
					// 	el: $paginationCustom.get(0),
					// 	clickable: true
					// },

					// Navigation arrows
					navigation: {
						nextEl: $navNextCustom.get(0),
						prevEl: $navPrevCustom.get(0),
					},

					// Responsive breakpoints
					breakpoints: {
						// when window width is >= 768px
						// 768: {
						// 	slidesPerView: 2,
						// },
						// when window width is >= 1024px
						1024: {
							slidesPerView: 2,
						},
						// when window width is >= 1280px
						1280: {
							slidesPerView: 3,
						}
					},

					speed: 800

				});

				swiperNews.on('slideChange', function() {
					let index = this.realIndex

					let currentPagination = flexibleNews.querySelectorAll('.swiper-pagination-custom .swiper-pagination-custom-item')[index]

					gsap.to(flexibleNews.querySelector('.swiper-pagination-custom-cursor'), {
						left: currentPagination.offsetLeft,
						duration: .8,
						ease: 'back.inOut'
					})
				})

				flexibleNews.querySelectorAll('.swiper-pagination-custom-item').forEach(function(el, index) {
					el.addEventListener('click', function() {
						swiperNews.slideToLoop(index)
					})
				})
			})

		}

		/**
		 * Slider Testimonials (w/ Swiper)
		 */
		let swiperTestimonials = null;

		if ( $sliderTestimonials.length ) {

			let
				$paginationCustom = $('.flexible-testimonials').find('.swiper-pagination-custom'),
				$navNextCustom 	  = $('.flexible-testimonials').find('.swiper-button-next-custom'),
				$navPrevCustom    = $('.flexible-testimonials').find('.swiper-button-prev-custom')
			;

			swiperTestimonials = new Swiper($sliderTestimonials.get(0), {

				// configure Swiper to use modules
				modules: [Navigation, Pagination],

				// Optional parameters
				loop: true,
				slidesPerView: 1,
				spaceBetween: 32,

				// If we need pagination
				pagination: {
					el: $paginationCustom.get(0),
					clickable: true
				},

				// Navigation arrows
				navigation: {
					nextEl: $navNextCustom.get(0),
					prevEl: $navPrevCustom.get(0),
				},

				// Responsive breakpoints
				breakpoints: {
					// when window width is >= 768px
					// 768: {
					// 	slidesPerView: 2,
					// },
					// when window width is >= 1024px
					1024: {
						slidesPerView: 2,
					},
				}

			});

		}

		/**
         * Video Player's (Plyr)
         */
		if ( $flexibleVideos.length ) {

			$.each( $flexibleVideos, (index, element) => {

				$(element).attr('id', `video-player-${index + 1}`);

				let
					$plyrPlayer = $(element).find('.plyr-player'),
					$poster 	= $(element).find('.poster'),
					$playBtn 	= $(element).find('.play-btn')
				;

				const player = new Plyr($plyrPlayer);

				$playBtn.on('click', (e) => {

					if ( $poster.length ) {

						gsap.to($poster, {
							'autoAlpha': 0,
							'duration' : .3,
							'ease': "power1.inOut"
						});

					}

					gsap.to($playBtn, {
						'autoAlpha': 0,
						'duration' : .3,
						'ease': "power1.inOut"
					});

					player.play();

				});

				player.on('pause', (event) => {

					if ( $poster.length ) {

						gsap.to($poster, {
							'autoAlpha': 1,
							'duration' : .3,
							'ease': "power1.inOut"
						});

					}

					gsap.to($playBtn, {
						'autoAlpha': 1,
						'duration' : .3,
						'ease': "power1.inOut"
					});

				});

			});

		}

		/**
         * Section products
         */
		if ( $sectionProducts.length ) {

			$.each($sectionProducts, (index, element) => {

				const
					$universeList  = $(element).find('.universe-list').find('.universe-list-item'),
					$universeInfos = $(element).find('.universe-items').find('.universe-item')
				;

				$universeList.on('click', (e) => {

					let
						currentTarget 	= e.currentTarget,
						index 		  	= currentTarget.dataset.universeIndex,
						relatedUniverse = $(`.universe-item[data-universe-index="${index}"]`)
					;

					if ( !$(currentTarget).hasClass('active') ) {

						$universeList.removeClass('active');
						$universeInfos.removeClass('active');

						$(currentTarget).addClass('active');
						$(relatedUniverse).addClass('active');

					}

				});

			});

		}

		/*
		|
		| DOM Events
		|-----------------------
		*/

		/**
         * Scroll
         */
		$( window ).scroll(function() {

			scrollTop = $( window ).scrollTop();

			if ( $header.length ) {

				stickyHeader(scrollTop, offset, lastScrollTop);

			}

			lastScrollTop = scrollTop;

		});



        $body.on('loaderEnd', () => console.log('ended'))

		document.querySelectorAll('[data-src], [data-bg]').forEach((item) => app.lazyLoader.observe(item))


		/*
        |
        | instagram
        |-----------------
        */
        
		$(document).ready(function() {
			// Check if the main container for the instagram feed exists
                if ($('.flexible-instagram-feed').length > 0) {
    
                    // Use querySelector with your class name to get the first element with the specified class.
                    // Make sure to replace '{$yourClassName}' with your actual class name.
                    var widgetContainer = document.querySelector('.flexible-instagram-feed');
    
                    // If the widgetContainer is null, the script stops here.
                    if (!widgetContainer) return;
    
                    var observer = new IntersectionObserver(function(entries) {
                        // Checking to see if the widget is in view
                        if (entries[0].isIntersecting === true) {
                            // As the widget comes into view, the getSocialData function will be called
                            getSocialData();
    
                            // We're unobserving the widget container as we no longer need to know if it's in view post initial load.
                            observer.unobserve(widgetContainer);
                        }
                    }, {threshold: [0]});
    
                    // We're observing the widget container to see when it comes into view.
                    observer.observe(widgetContainer);
    
                    // Defining getSocialData outside the observer's scope so it's accessible.
                    function getSocialData() {
                        // Check for '.sbi_item' within the '.flexible-instagram-feed' div
                        // This interval keeps checking until the required elements are loaded in the DOM
                        var checkExist = setInterval(function() {
                            if ($('.sbi_item').length > 0) {
                                clearInterval(checkExist);
                                let count = 0;
    
                                // Once we have the items, we iterate through them, limiting the count to 6
                                $('.sbi_item').each(function() {
                                    if (count < 6) {
                                        let article = $(this),
                                            link = article.find('.sbi_photo').attr('href'),
                                            image = article.find('.sbi_photo').attr('data-full-res');
    
                                        // Setting the attributes for each individual Instagram item
                                        $('.ig-item').eq(count).attr({
                                            href: link,
                                            style: 'background-image: url('+image+');'
                                        });
    
                                        count++;
                                    }
                                });
                            }
                        }, 1000); // Interval checks every second
                    }
                }
            });
        } 

}

