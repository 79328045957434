/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
	init: (app, gsap, Swiper, Navigation, Pagination) => {
		/*
		|
		| Constants
		|-----------
		*/
        const
			$body 				= $('body'),
			$slidersUniverse	= $('[data-swiper="universe"]'),
			$sectionShowrooms	= $('.section-showrooms'),
			$sectionProducts	= $('.section-products')
		;

		/**
         * Slider Universe (w/ Swiper)
         */
		if ( $slidersUniverse.length ) {

			$.each($slidersUniverse, (index, element) => {

				let swiperUniverse = null;

				let $sliderUniverse = $(element);

				swiperUniverse = new Swiper($sliderUniverse.get(0), {

					// configure Swiper to use modules
					modules: [Navigation, Pagination],

					// Optional parameters
					loop: true,

					// Navigation arrows
					navigation: {
						nextEl: '.swiper-button-next',
						prevEl: '.swiper-button-prev',
					},

				});

			});

		}

		/**
         * Showrooms
         */
		if ( $sectionShowrooms.length ) {

			const
				$showroomsList 	= $('.showrooms-list').find('.showroom-item'),
				$showroomsInfos = $('.showrooms-details').find('.showroom-infos'),
				$showroomsImgs 	= $('.showrooms-details').find('.showroom-img')
			;

			$showroomsList.on('click', (e) => {

				let
					currentTarget = e.currentTarget,
					index 		  = currentTarget.dataset.showroomIndex,
					relatedInfos  = $(`.showroom-infos[data-showroom-index="${index}"]`),
					relatedImg    = $(`.showroom-img[data-showroom-index="${index}"]`)
				;

				if ( !$(currentTarget).hasClass('active') ) {

					$showroomsList.removeClass('active');
					$showroomsInfos.removeClass('active');
					$showroomsImgs.removeClass('active');

					$(currentTarget).addClass('active');
					$(relatedInfos).addClass('active');
					$(relatedImg).addClass('active');

				}

			});

		}

		/**
         * Products
         */
		if ( $sectionProducts.length ) {

			const
				$universeList  = $('.universe-list').find('.universe-list-item'),
				$universeInfos = $('.universe-items').find('.universe-item')
			;

			$universeList.on('click', (e) => {

				let
					currentTarget 	= e.currentTarget,
					index 		  	= currentTarget.dataset.universeIndex,
					relatedUniverse = $(`.universe-item[data-universe-index="${index}"]`)
				;

				if ( !$(currentTarget).hasClass('active') ) {

					$universeList.removeClass('active');
					$universeInfos.removeClass('active');

					$(currentTarget).addClass('active');
					$(relatedUniverse).addClass('active');

				}

			});

		}

		/*
		|
		| DOM Events
		|-----------------------
		*/

	}
}
