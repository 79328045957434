/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, gsap) => {

        let items = document.querySelectorAll('.video')

        items.forEach(function(item) {

            let iframe = item.querySelector('iframe'),
                src = iframe.dataset.video,
                cover = item.querySelector('.video-cover')

            cover.addEventListener('click', function() {
                iframe.setAttribute('src', src)

                gsap.to(this, {opacity: 0, display: 'none', duration: .4})
            })

        })
    }
}
