/*** IMPORTS FROM imports-loader ***/
var define = false;

/*
|
| Importing Libs
|------------------
*/
require('@lib/iziModal/js/iziModal.js')($); //désolé
import Swiper, { Navigation, Pagination } from 'swiper';
import Plyr from 'plyr';
import gsap from 'gsap';
import SplitText from '@lib/gsap-pro/SplitText';
import ScrollTo from 'gsap/ScrollToPlugin';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(ScrollTo);

/*
|
| Importing Components
|-----------------------
*/
import customGoogleMap from '@components/custom-google-map';
// import MaterializeForm from '@components/materialize-form';
import Kira from '@components/kira';
import Menu from '@components/menu';

/*
|
| Importing Utils
|-------------------
*/
import * as app from '@utils/global';
import Router from '@utils/router';
import Ajax from '@utils/ajax';

/*
|
| Importing App files
|----------------------
*/
import main from '@pages/main';
import news from '@pages/news';
import home from '@pages/home';
import stores from '@pages/stores';
import store from '@pages/store';
import products from '@pages/products';
import animations from '@pages/animations';
// import sample from '@pages/sample';
import contact from '@pages/contact';
import form from '@pages/form';

import whoWeWare from '@blocks/who-we-are'
import slider_product from '@blocks/slider_product'
import slider_atelier from '@blocks/slider_atelier'
import slider_text from '@blocks/slider_text'
import video from '@blocks/video'
import testimonials from '@blocks/testimonials'
import text_image_2 from '@blocks/text_image_2'

/*
|
| Routing
|----------
*/
const routes = new Router([
    {
      'file': animations,
      'dependencies': [app, gsap, ScrollTrigger, SplitText, Menu, Kira]
    },
	  {
      'file': main,
      'dependencies': [app, gsap, Plyr, Swiper, Navigation, Pagination]
    },
    {
      'file': news,
      'dependencies': [app, gsap, Ajax],
      'resolve': '#page-magazine'
    },
    {
      'file': home,
      'dependencies': [app, gsap, Swiper, Navigation, Pagination],
      'resolve': '#page-home'
    },
    {
      'file': stores,
      'dependencies': [app, customGoogleMap],
      'resolve': '#page-stores'
    },
    {
      'file': store,
      'dependencies': [app, gsap, Swiper, Navigation, Pagination],
      'resolve': '#page-stores-single'
    },
    {
      'file': products,
      'dependencies': [app, gsap, Ajax, ScrollTrigger],
      'resolve': '#page-products'
    },
	  // {
    //   'file': sample,
    //   'dependencies': [app],
    //   'resolve': '#page-sample'
    // },
    {
      'file': contact,
      'dependencies': [app, customGoogleMap, Swiper, Navigation, Pagination, Ajax],
      'resolve': '#page-contact'
    },
    {
      'file': form,
      'dependencies': [app],
      'resolve': '#page-form'
    },
    {
      'file': whoWeWare,
      'dependencies': [app, Swiper, Navigation]
    },
    {
      'file': slider_product,
      'dependencies': [app, gsap, Swiper, Navigation]
    },
    {
      'file': slider_atelier,
      'dependencies': [app, gsap, Swiper]
    },
    {
      'file': slider_text,
      'dependencies': [app, gsap, Swiper]
    },
    {
      'file': video,
      'dependencies': [app, gsap]
    },
    {
      'file': testimonials,
      'dependencies': [app, gsap, Swiper, Navigation]
    },
    {
      'file': text_image_2,
      'dependencies': [app, gsap, Swiper, Navigation]
    }
]);

/*
|
| Run
|------
*/
(($) => { routes.load() })(jQuery);

