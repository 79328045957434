/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, gsap, Ajax, ScrollTrigger) => {
        // products_banner-colors
        /*
        |
        | Constants
        |------------
        */
        const
            loadMoreBtn = document.querySelector('.products_banner-colors'),
            productsList = document.querySelector('.products-list'),
            loadMoreContainer = document.querySelector('.load-more-container'),
            loadMore = document.querySelector('.load-more'),
            loaderContainer = document.querySelector('.loader-container')


        /*
        |
        | base content
        |-----------
        */
        let postID = document.querySelector('[data-pid]').dataset.pid;
        let xhr = null

        console.log(postID);

        let baseContent = {
            title: document.querySelector('.products-list-header .title-xl').innerHTML,
            text: document.querySelector('.products-list-header .cms').innerHTML
        }


		/*
		|
		| params filter
		|-----------
		*/
        let search = window.location.search.substring(1)
		if (search) {
			let search_json = JSON.parse('{"' + decodeURI(search).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')

            if (search_json.colors) {
                search_json.colors.split('%2C').forEach(color => {
                    let filter = document.querySelector('.products_banner-colors-item[data-slug="'+ color +'"]')

                    filter.classList.remove('--muted')
                    filter.classList.add('--active')
                    document.querySelectorAll('.products_banner-colors-item:not(.--active)').forEach(function(item) {
                        item.classList.add('--muted')
                    })
                })
            }

            if (search_json.style) {
                search_json.style.split('%2C').forEach(style => {
                    let filter = document.querySelector('.products_banner-styles-item[data-slug="'+ style +'"]')

                    filter.classList.add('--active')
                })
            }

			filterAjax()
		}


        /*
        |
        | click ajax
        |--------------
        */
        document.querySelectorAll('.products_banner-colors-item, .products_banner-styles-item').forEach(function(filter) {
            filter.addEventListener('click', (e) => {
                e.preventDefault()

                if(xhr) xhr.abort()

                if (filter.classList.contains('--active')) {
                    filter.classList.remove('--active')
                    filter.classList.add('--muted')
                } else {
                    filter.classList.remove('--muted')
                    filter.classList.add('--active')
                    document.querySelectorAll('.products_banner-colors-item:not(.--active)').forEach(function(item) {
                        item.classList.add('--muted')
                    })
                }

                filterAjax()
            })
        })


        /*
        |
        | filter ajax
        |--------------
        */
        function filterAjax() {
            let data = {
                types: productsList.dataset.types,
                universe: productsList.dataset.universe,
                colors: [],
                style: []
            }

            document.querySelectorAll('.products_banner-colors-item.--active').forEach(function(card) {
                data.colors.push(card.dataset.slug)
            })

            document.querySelectorAll('.products_banner-styles-item.--active').forEach(function(card) {
                data.style.push(card.dataset.slug)
            })


            let dataParams = {}

            if (data.colors.length > 0) {
                dataParams.colors = data.colors
            }

            if (data.style.length > 0) {
                dataParams.style = data.style
            }

            let urlParams = new URLSearchParams(dataParams)

            window.history.replaceState('', '', window.location.origin + window.location.pathname + '?' + urlParams.toString())


            xhr = new Ajax({
                url: `/ajax/products/${postID}/`,
                data: data,
                onLoadStart: () => {
                    productsList.innerHTML = ''

                    productsList.classList.add('loading')
                },
                onSuccess: (response) => {
                    productsList.innerHTML += response

                    let responseTitle = productsList.querySelector('.products-list-header .title-xl')

                    if (!responseTitle.innerText) {
                        responseTitle.innerHTML = baseContent.title
                    }

                    let responseText = productsList.querySelector('.products-list-header .cms')

                    if (!responseText.innerText) {
                        responseText.innerHTML = baseContent.text
                    }

                    productsList.classList.remove('loading')

                    productsList.querySelectorAll('[data-src], [data-bg]').forEach((item) => app.lazyLoader.observe(item))

                    ScrollTrigger.refresh();

                }
            })
        }
    }
}
