/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, gsap, Ajax) => {

        /*
        |
        | Constants
        |------------
        */
        const
            loadMoreBtn = document.querySelector('.news-load'),
            postsContainer = document.querySelector('.news-container'),
            loadMoreContainer = document.querySelector('.load-more-container'),
            loadMore = document.querySelector('.load-more'),
            loaderContainer = document.querySelector('.loader-container')
        ;

        let xhr = null

        /*
        |
        | Load more ajax
        |--------------
        */
        loadMoreBtn.addEventListener('click', (e) => {
            e.preventDefault()

            if(xhr) xhr.abort()

            const offset = postsContainer.querySelectorAll('.custom-card').length;
            const url = `/ajax/posts/${offset}/`;

            let featureds = []

            document.querySelectorAll('.custom-card.featured').forEach(function(card) {
                featureds.push(card.dataset.id)
            })

            xhr = new Ajax({
                url: url,
                data: {
                    featureds: featureds
                },
                onLoadStart: () => {
                    postsContainer.classList.add('loading')
                },
                onSuccess: (response) => {
                    postsContainer.innerHTML += response

                    postsContainer.classList.remove('loading')

                    if(postsContainer.querySelector('.no-more-post')) {
                        loadMoreBtn.remove()
                    }
                }
            })
        })
    }
}
