/*** IMPORTS FROM imports-loader ***/
var define = false;

export default {
    init: (app, gsap, Swiper, Navigation) => {

        const speed = 800

        let items = document.querySelectorAll('.text_image_2-slider')

        items.forEach(function(slider) {
            const swiper = new Swiper(slider, {
                simulateTouch: false,
                slidesPerView: 1,
                loop: true,
                modules: [Navigation],
                navigation: {
                    nextEl: '.text_image_2-controls-next',
                    prevEl: '.text_image_2-controls-prev',
                },
                speed: speed
            })

            swiper.on('slideChange', function() {
                slider.querySelectorAll('.text_image_2-image').forEach(function(image) {
                    app.lazyLoader.observe(image)
                })
            })

        })
    }
}
